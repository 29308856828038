// src/pages/Dofus/DofusVideo.js
import React from 'react';
import VideoPlayer from '../../../composants/VideoPlayer';
import NavContenu from '../../../composants/Nav-contenu';

export default function DofusVideo() {
  const API_KEY = 'AIzaSyDmKxhtlFeM5uljkI9wZTYus9SbxqZbGqc'; // Votre clé API
  const PLAYLIST_ID = 'PLPYIAexfSG__13-tx7RU0-EBAlPXmxR3H'; // ID de votre playlist
  
  return (
    <>
      <NavContenu currentGame="dofusepopeefraternelle"  />
      <VideoPlayer playlistId={PLAYLIST_ID} apiKey={API_KEY} title="Dernières vidéos de L'aventure à deux" />
    </>
  );
}
