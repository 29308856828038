import React, { useState } from 'react';
import '../../style/admin/AdminPlanningVideo.scss';

export default function AdminPlanningVideo() {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <div className={`admin-planning-video ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Planification des vidéos</h2>

      <div className="calendar-container">
        {/* Intégration de Google Agenda */}
        <iframe
          src="https://calendar.google.com/calendar/embed?src=lsv.lossantosv%40gmail.com&ctz=Europe%2FParis"
          style={{
            border: 0,
          }}
          width="80%"
          height="600"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
      <div className="calendar-edit">
        <p>
          Vous souhaitez modifier cet agenda ? Cliquez sur le bouton ci-dessous
          pour accéder a l'interface d'édition Google Agenda :
        </p>
        <a
          href="https://calendar.google.com/calendar/u/0/r?pli=1"
          target="_blank"
          rel="noopener noreferrer"
          className="edit-calendar-link"
        >
          Modifier l'Agenda / Ajouter une vidéo
        </a>
      </div>
    </div>
  );
}
